<template>
  <div class="q-pa-xl full-width" id="page-modules-styles">
    <div class="text-h4">{{ mode === 'create' ? 'Add' : 'Edit' }} Customer</div>
    <q-form @submit="mode === 'create' ? createCustomer() : updateCustomer()" autocomplete="off">
      <div class="row q-mt-xl">
        <div class="col-4">
          <div class="text-h6 q-mt-sm text-right q-mr-lg">Company Information</div>
        </div>
        <q-card flat class="col-8 bg-lmsBackground">
          <q-card-section class="q-py-none">
            <q-input v-model="customer.name" label="Company Name" :rules="formRules.name" />
          </q-card-section>
          <q-card-section>
            <q-input v-model="customer.city" label="City" :rules="formRules.city" />
          </q-card-section>
          <q-card-section>
            <q-select v-model="customer.state" map-options emit-value :options="this.$consts.states" label="State" :rules="formRules.state" />
          </q-card-section>
          <q-card-section>
            <q-select v-model="customer.company_type" :options="locationTypesList" label="Company Type" :rules="formRules.companyType" />
          </q-card-section>
        </q-card>
      </div>

      <div v-if="mode === 'create'" class="row q-mt-xl">
        <div class="col-4">
          <div class="text-h6 q-mt-sm text-right q-mr-lg">Admin User</div>
        </div>
        <q-card flat class="col-8 bg-lmsBackground">
          <q-card-section class="q-py-none">
            <q-input v-model="customer.first_name" label="First Name" :rules="formRules.firstName" />
          </q-card-section>
          <q-card-section>
            <q-input v-model="customer.last_name" label="Last Name" :rules="formRules.lastName" />
          </q-card-section>
          <q-card-section>
            <q-input v-model="customer.email" label="Email" :rules="formRules.email" />
          </q-card-section>
          <q-card-section>
            <q-input v-model="customer.password" type="password" label="Password" :rules="formRules.password" />
          </q-card-section>
          <q-card-section>
            <q-input v-model="customer.password_confirmation" type="password" label="Confirm Password" lazy-rules :rules="formRules.passwordConfirmation" />
          </q-card-section>
        </q-card>
      </div>
      <div v-if="mode === 'create'" class="row q-mt-xl">
          <div class="col-4">
            <div class="text-h6 q-mt-sm text-right q-mr-lg">Other Information</div>
          </div>

        <div class="col-8">
          <div class="col-12">
            The following items will also be created:<br />
            <q-list class="q-mt-sm">
              <q-item>
                <q-item-section>
                  <q-item-label>Roles</q-item-label>
                  <q-item-label caption>Site Admin, Default User</q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label>Departments</q-item-label>
                  <q-item-label caption>Sporting Goods, Lawn & Garden</q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label>Location Types</q-item-label>
                  <q-item-label caption>Headquarters, Store, Warehouse</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </div>
        </div>
      </div>

      <div class="row justify-end q-mt-lg">
        <q-btn :loading="savingCustomer" type="submit" color="primary">{{ mode === 'create' ? 'Add' : 'Save' }} Customer</q-btn>
      </div>
    </q-form>

  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'AddUserPage',
  data () {
    return {
      mode: 'create',
      savingCustomer: false,
      customer: {}
    }
  },
  computed: {
    locationTypesList: function () {
      return this.$store.getters['locationTypes/list']
    },
    formRules () {
      return {
        name: [ this.$formRules.required('Company Name'), this.$formRules.minimum(4) ],
        city: [ this.$formRules.required('City'), this.$formRules.minimum(4) ],
        state: [ this.$formRules.required('State') ],
        companyType: [ this.$formRules.required('Company Type') ],
        firstName: [ this.$formRules.required('First Name'), this.$formRules.minimum(2) ],
        lastName: [ this.$formRules.required('Last Name'), this.$formRules.minimum(3) ],
        email: [ this.$formRules.required('Email') ],
        password: [ this.$formRules.required('Password') ],
        passwordConfirmation: [ this.$formRules.confirmPassword(this.customer.password) ]
      }
    }
  },
  methods: {
    async createCustomer () {
      this.savingCustomer = true

      this.customer.type = this.customer.company_type.label
      this.customer.nest_type_uid = this.customer.company_type.value
      this.customer.username = this.customer.email

      try {
        await this.$axios.post(this.$consts.ADD_NEW_CUSTOMER_URL, this.customer)
        this.$successMsg()
        this.savingCustomer = false
        this.$router.push({ name: 'customers' })
      } catch (e) {
        this.$failureMsg()
        this.savingCustomer = false
      }
    },
    updateCustomer () {
      this.savingCustomer = true
      this.customer.type = this.customer.company_type.label
      this.customer.nest_type_uid = this.customer.company_type.value

      this.$store.dispatch('auth/updateLocation', this.customer).then((resp) => {
        this.$successMsg('Customer was updated')
        this.savingCustomer = false
        this.$router.push({ name: 'customers' })
      }).catch((err) => {
        this.$failureMsg()
        this.savingCustomer = false
        this.$log(this.$options.name, 'Couldnt create customer', err)
      })
    }
  },
  async created () {
    this.$store.dispatch('locationTypes/fetch')
    if (this.$route.name === 'customersEdit') {
      this.$q.loading.show()
      this.mode = 'edit'

      try {
        let res = await this.$axios.get(`${this.$consts.GET_CUSTOMER_URL}/${this.$route.params.uid}`)
        this.customer = res.data
        Vue.set(this.customer, 'company_type', { label: this.customer.nest_type_name, value: this.customer.nest_type_uid })
        this.$q.loading.hide()
      } catch (e) {
        this.$failureMsg(`Couldn't retrieve customer`)
        this.$q.loading.hide()
        throw e
      }
    }
  }
}
</script>
